<template>
    <div class="org-search flex flex-col items-center justify-center w-full">
        <div class="mb-[-16px] bg-white xs:w-[375px] w-[270px] rounded-[9px] flex-col justify-center items-center inline-flex shadow-card z-10">
            <div class="self-stretch py-2 justify-center items-center gap-2 inline-flex">
                <div class="grow shrink basis-0 px-2 justify-start items-start gap-2 flex">
                    <button
                        v-for="(option, index) in tripTypes"
                        class="textNeutral text-neutralpink"
                        :class="
                            option.value === trip.tripType
                                ? '!text-JauntrPrimary-500 !bg-red-100'
                                : 'hover:bg-grey-1 active:bg-grey-2  focus-within:bg-grey-2'
                        "
                        @click="setTripType(option)"
                    >
                        <span class="button-2"> {{ option.label }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="lg:w-[1080px] md:w-[718px] bg-white md:shadow-card w-full bg-white md:rounded-[9px] flex-col justify-center items-center gap-2.5 inline-flex">
            <div
                :class="addExtraPadding() ? 'py-8 ' : 'py-4'"
                class="lg:w-[1080px] md:w-[718px] pt-8 px-4 w-full gap-3 flex items-end justify-end xs:justify-center flex-wrap"
            >
                <div
                    :class="{ 'location-selector--one-way': !isRound }"
                    class="location-selector w-full flex items-end"
                >
                    <div class="date-wrapper md:pt-0">
                        <div class="label-container">
                            <div class="label">
                                {{ languageData.departingFrom }}
                            </div>
                        </div>

                        <VDropdown
                            ref="departureForm"
                            :shown="showDepartureDropdown"
                            :auto-hide="false"
                            :triggers="[]"
                            :distance="6"
                            placement="bottom-start"
                            aria-id="departingFrom"
                            @blur="toggleLocationDropdown(1, false)"
                        >
                            <input
                                v-model="searchKeywordDeparture"
                                type="text"
                                enterkeyhint="next"
                                :class="trip.hasDepartureAirportError ? 'border !border-JauntrPrimary-500' : ''"
                                class="pg-search-input-wrapper body-1"
                                :placeholder="languageData.searchLocations"
                                @input="resetScroll(1)"
                                @keydown.tab="moveToDropdown(1)"
                                @keydown.enter="selectFirst(1)"
                                @focus="(e) => selectText(e, 1)"
                            >

                            <template #popper="{ hide }">
                                <div
                                    ref="departureDropdown"
                                    tabindex="0"
                                    class="location-search"
                                >
                                    <!--  v-show="searchKeywordDeparture || filteredHistoryDeparture" -->
                                    <div class="location-search-col w-full">
                                        <button
                                            v-for="(option, index) in filteredHistoryDeparture"
                                            :id="'departingHistory' + index"
                                            :key="index"
                                            tabindex="0"
                                            :class="
                                                option.iata === extractKeywords(searchKeywordDeparture).iata
                                                    ? 'bg-red-100'
                                                    : ''
                                            "
                                            class="location-search-col-item"
                                            @click="setLocation(option, null, hide)"
                                        >
                                            <div class="location-search-col-text group">
                                                <i
                                                    class="icon icon-history-outlined"
                                                    :class="
                                                        option.iata === extractKeywords(searchKeywordDeparture).iata
                                                            ? '!bg-red-action'
                                                            : ''
                                                    "
                                                />

                                                <div class="flex flex-col w-full">
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordDeparture).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="body-2 inline text-wrap body-2-bold inline-flex w-full text-wrap"
                                                    >
                                                        <span class="truncate-text">
                                                            {{ option.name }}{{
                                                                '&nbsp;(' + option.iata + ')'
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordDeparture).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="label truncate"
                                                    >
                                                        {{ option.region_name || option.city }}, {{
                                                            option.country
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>

                                        <button
                                            v-for="(option, index) in departureAirports"
                                            :id="'departing' + index"
                                            :key="index"
                                            tabindex="0"
                                            :class="
                                                [
                                                    option.iata === extractKeywords(searchKeywordDeparture).iata
                                                        ? 'bg-red-100'
                                                        : '',
                                                    searchKeywordDeparture ? 'first:bg-grey-1' : '',
                                                ]
                                            "
                                            class="location-search-col-item"
                                            @click="setLocation(option, null, hide)"
                                        >
                                            <div
                                                class="location-search-col-text group"
                                                @click="hide()"
                                            >
                                                <i
                                                    class="icon icon-jauntr-plane"
                                                    :class="
                                                        option.iata === extractKeywords(searchKeywordDeparture).iata
                                                            ? '!bg-red-action'
                                                            : ''
                                                    "
                                                />

                                                <div class="flex flex-col w-full">
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordDeparture).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="body-2 inline text-wrap body-2-bold inline-flex w-full text-wrap"
                                                    >
                                                        <span class="truncate-text">
                                                            {{ option.name }}{{
                                                                '&nbsp;(' + option.iata + ')'
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordDeparture).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="label truncate"
                                                    >
                                                        {{ option.region_name || option.city }}, {{
                                                            option.country
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        departureAirports.length === 0 && searchKeywordDeparture
                                            && filteredHistoryDeparture.length === 0
                                    "
                                    class="location-search p-4 body-2 body-2-bold"
                                >
                                    {{ languageData.noMatchingResultsFound }}
                                </div>
                            </template>
                        </VDropdown>

                        <div class="errorMessage">
                            <span v-show="trip.hasDepartureAirportError">
                                {{ languageData.selectValidLocationError }}
                            </span>
                        </div>
                    </div>

                    <button
                        class="w-full button button-icon button-white mx-[-8px] z-10 mb-[2px] switch-location-button"
                        @click="switchLocation()"
                    >
                        <div class="w-6 h-6">
                            <i class="icon icon-arrow-switch text-grey-9" />
                        </div>
                    </button>

                    <div class="date-wrapper">
                        <div class="label-container">
                            <div class="label">
                                {{ languageData.goingTo }}
                            </div>
                        </div>

                        <VDropdown
                            ref="arrivalForm"
                            :shown="showArrivalDropdown"
                            :auto-hide="false"
                            :triggers="[]"
                            :distance="6"
                            placement="bottom-start"
                            aria-id="goingTo"
                            @blur="toggleLocationDropdown(0, false)"
                        >
                            <input
                                ref="selectArrival"
                                v-model="searchKeywordArrival"
                                type="text"
                                enterkeyhint="next"
                                :class="trip.hasArrivalAirportError ? 'border !border-JauntrPrimary-500' : ''"
                                class="pg-search-input-wrapper body-1"
                                :placeholder="languageData.searchLocations"
                                @input="resetScroll(0)"
                                @keydown.tab="moveToDropdown(0)"
                                @keydown.enter="selectFirst(0)"
                                @focus="(e) => selectText(e, 0)"
                            >

                            <template #popper="{ hide }">
                                <div
                                    ref="arrivalDropdown"
                                    tabindex="0"
                                    class="location-search"
                                >
                                    <div class="location-search-col w-full">
                                        <!-- Filtered history section -->
                                        <button
                                            v-for="(option, index) in filteredHistoryArrival"
                                            :id="'arrivalHistory' + index"
                                            :key="index"
                                            tabindex="0"
                                            :class="
                                                option.iata === extractKeywords(searchKeywordArrival).iata
                                                    ? 'bg-red-100'
                                                    : ''
                                            "
                                            class="location-search-col-item"
                                            @click="setLocation(null, option, hide)"
                                        >
                                            <div class="location-search-col-text group">
                                                <i
                                                    class="icon icon-history-outlined"
                                                    :class="
                                                        option.iata === extractKeywords(searchKeywordArrival).iata
                                                            ? '!bg-red-action'
                                                            : ''
                                                    "
                                                />

                                                <div class="flex flex-col w-full">
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordArrival).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="body-2 inline text-wrap body-2-bold inline-flex w-full text-wrap"
                                                    >
                                                        <span class="truncate-text">
                                                            {{ option.name }}{{
                                                                '&nbsp;(' + option.iata + ')'
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordArrival).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="label truncate"
                                                    >
                                                        {{ option.city || option.region_name }}, {{
                                                            option.country
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>

                                        <!-- Arrival airports section -->
                                        <button
                                            v-for="(option, index) in arrivalAirports"
                                            :id="'arrival' + index"
                                            :key="index"
                                            tabindex="0"
                                            :class="
                                                option.iata === extractKeywords(searchKeywordArrival).iata
                                                    ? 'bg-red-100'
                                                    : ''
                                            "
                                            class="location-search-col-item"
                                            @click="setLocation(null, option, hide)"
                                        >
                                            <div class="location-search-col-text group">
                                                <i
                                                    class="icon icon-jauntr-plane"
                                                    :class="
                                                        option.iata === extractKeywords(searchKeywordArrival).iata
                                                            ? '!bg-red-action'
                                                            : ''
                                                    "
                                                />

                                                <div class="flex flex-col w-full">
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordArrival).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="body-2 inline text-wrap body-2-bold inline-flex w-full text-wrap"
                                                    >
                                                        <span class="truncate-text">
                                                            {{ option.name }}{{
                                                                '&nbsp;(' + option.iata + ')'
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        :class="
                                                            option.iata === extractKeywords(searchKeywordArrival).iata
                                                                ? '!text-red-action'
                                                                : ''
                                                        "
                                                        class="label truncate"
                                                    >
                                                        {{ option.city || option.region_name }}, {{
                                                            option.country
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <!-- No matching results section -->
                                <div
                                    v-if="
                                        arrivalAirports.length === 0 && searchKeywordArrival
                                            && filteredHistoryArrival.length === 0
                                    "
                                    class="location-search p-4 body-2 body-2-bold"
                                >
                                    {{ languageData.noMatchingResultsFound }}
                                </div>
                            </template>
                        </VDropdown>

                        <div class="errorMessage">
                            <span v-show="trip.hasArrivalAirportError">
                                {{ languageData.selectValidLocationError }}
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    :class="{ 'date-picker-group--one-way': !isRound }"
                    class="date-picker-group flex gap-x-3 items-end mt-[23px] xs:mt-0"
                >
                    <div class="pg-search-date-picker-container">
                        <div class="label-container">
                            <div class="label">
                                {{ languageData.searchDates }}
                            </div>
                        </div>
                        <div
                            class="inline-flex items-center w-full"
                            :class="trip.hasDepartureDateError ? 'hasError' : ''"
                        >
                            <div class="w-6 h-6 mr-[-32px] ml-2 z-10">
                                <i class="icon icon-calendar-outlined" />
                            </div>
                            <input
                                ref="departurePicker"
                                v-model="trip.departureDate"
                                class="date-picker cursor-pointer"
                                placeholder="Depart"
                                enterkeyhint="next"
                            >
                        </div>
                        <div class="label label-red mb-[-23px] h-[18px]">
                            <span v-show="trip.hasDepartureDateError">
                                {{ languageData.enterValidDateError }}
                            </span>
                        </div>
                    </div>

                    <Transition name="slide-fade">
                        <div
                            v-show="isRound"
                            class="pg-search-date-picker-container"
                        >
                            <div
                                class="flex items-center w-full"
                                :class="trip.hasArrivalDateError ? 'hasError' : ''"
                            >
                                <div class="w-6 h-6 mr-[-32px] ml-2 z-10">
                                    <i class="icon icon-calendar-outlined" />
                                </div>
                                <input
                                    ref="arrivalPicker"
                                    v-model="trip.arrivalDate"
                                    class="date-picker cursor-pointer"
                                    placeholder="Return"
                                    enterkeyhint="next"
                                >
                            </div>
                            <div class="label label-red mb-[-23px] h-[18px]">
                                <span v-show="trip.hasArrivalDateError">
                                    {{ languageData.enterValidDateError }}
                                </span>
                            </div>
                        </div>
                    </Transition>
                </div>

                <div
                    :class="{ 'travellers-selector--one-way': !isRound }"
                    class="travellers-selector dropdown-wrapper mt-[23px] lg:mt-auto"
                >
                    <div class="label-container">
                        <div class="label">
                            {{ languageData.travellers }}
                        </div>
                    </div>
                    <VDropdown
                        :distance="6"
                        placement="bottom-start"
                        @show="showTravellerDropdown = true"
                        @hide="showTravellerDropdown = false"
                    >
                        <button
                            ref="travellersTriggerBtn"
                            class="dropdown-button cursor-pointer w-full"
                            :class="showTravellerDropdown ? 'border-grey-7' : ' border-grey-3'"
                        >
                            <div class="dropdown-button-inner">
                                <div class="body-1 whitespace-nowrap">
                                    {{ trip.travellers.adult + trip.travellers.children }}
                                    {{
                                        trip.travellers.adult + trip.travellers.children > 1
                                            ? languageData.travellers
                                            : languageData.traveller
                                    }}
                                </div>
                                <div
                                    class="icon icon-arrow-drop-down-filled mr-2"
                                    :data-opened="showTravellerDropdown"
                                />
                            </div>
                        </button>

                        <template #popper>
                            <div class="traveller-dropdown">
                                <div class="traveller-dropdown-inner">
                                    <div class="traveller-dropdown-label">
                                        <div class="body-2">
                                            {{ languageData.adults }}
                                        </div>
                                    </div>
                                    <div class="traveller-buttons">
                                        <button
                                            class="icon-button-wrapper icon-button"
                                            :disabled="trip.travellers.adult < 2"
                                            @click="addTraveller(true, -1)"
                                        >
                                            <i class="icon-button-dash-outlined icon-button-small" />
                                        </button>
                                        <div class="traveller-label">
                                            {{ trip.travellers.adult }}
                                        </div>
                                        <button
                                            class="icon-button-wrapper icon-button"
                                            @click="addTraveller(true, 1)"
                                        >
                                            <i class="icon-button-add-filled icon-button-small" />
                                        </button>
                                    </div>
                                </div>
                                <div class="traveller-dropdown-inner">
                                    <div class="traveller-dropdown-label">
                                        <div class="body-2">
                                            {{ languageData.children }}
                                        </div>
                                        <div class="label">
                                            {{ languageData.childAgeRange }}
                                        </div>
                                    </div>
                                    <div class="traveller-buttons">
                                        <button
                                            class="icon-button-wrapper icon-button"
                                            :disabled="trip.travellers.children < 1"
                                            @click="addTraveller(false, -1)"
                                        >
                                            <i class="icon-button-dash-outlined icon-button-small" />
                                        </button>
                                        <div class="traveller-label">
                                            {{ trip.travellers.children }}
                                        </div>
                                        <button
                                            class="icon-button-wrapper icon-button"
                                            @click="addTraveller(false, 1)"
                                        >
                                            <i class="icon-button-add-filled icon-button-small" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </VDropdown>
                </div>

                <button
                    :class="{ 'search-button--one-way': !isRound }"
                    class="search-button button button-large button-red mt-[23px] lg:mt-auto h-[44px] grow"
                    @click="createSession()"
                >
                    <span class="button-1">{{ languageData.searchButton }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script src="./org-search.js"></script>

<style lang="scss" src="./org-search.scss"></style>
