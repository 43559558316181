export default {
    component: () => import('./pg-about.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'About - Jauntr',
        ui: {
            // linkDisplayText: getLinkDisplayText({ route: 'aboutUs' }),
            // showLinkInFooterColumn: 1,
            // showLinkInHeader: true,
            // smoothAutoscroll: false,
        },
    },
    name: 'about-us',
    path: '/about-us',
}
