<template>
    <div
        v-bind="$attrs"
        class="mlc-grid"
    >
        <slot />
    </div>
</template>

<script src="./mlc-grid.js" />
