<template>
    <div
        v-bind="$attrs"
        class="mlc-navigation"
    >
        <mlc-grid class="mlc-navigation__grid">
            <atm-item class="mlc-navigation__grid-logo">
                <slot name="logo" />
            </atm-item>
            <atm-item
                class="mlc-navigation__grid-menu"
                :class="{ 'mlc-navigation__grid-menu--open': menuOpen }"
            >
                <slot name="menu" />
            </atm-item>
            <atm-item class="mlc-navigation__grid-action">
                <slot name="action" />
            </atm-item>
            <atm-item class="mlc-navigation__grid-hamburger absolute right-[8px] md:relative md:hidden block">
                <atm-item
                    class="mlc-navigation__grid-hamburger-button p-[6px]"
                    @click.native="toggleMenu"
                >
                    <i
                        class="icon"
                        :class="
                            menuOpen
                                ? 'icon-close-no-circle-outlined'
                                : 'icon-hamburger-right-outlined'
                        "
                    />
                </atm-item>
            </atm-item>
        </mlc-grid>
    </div>

    <div
        v-if="menuOpen"
        class="h-[calc(100vh-48px)] overflow-scroll w-full z-30 top-[48px] fixed flex-col justify-between bg-white sm:hidden"
    >
        <div class="flex flex-col gap-6 pt-4">
            <div class="flex flex-col gap-y-2 px-4">
                <div
                    v-for="item in menu"
                    class="w-full px-3"
                >
                    <router-link
                        v-if="item.name"
                        :to="{ name: item.name }"
                        tabindex="-1"
                    >
                        <button
                            class="textNeutral2 !w-fit"
                            :href="item.href"
                            @click="(goToHash(item), menuOpen = !menuOpen)"
                        >
                            <span class="button-1">{{ item.copy }} </span>
                        </button>
                    </router-link>
                    <button
                        v-else
                        class="textNeutral2 !w-fit"
                        :href="item.href"
                        @click="goToHash(item)"
                    >
                        <span class="button-1">{{ item.copy }} </span>
                    </button>
                </div>
            </div>

            <div class="self-stretch h-[0px] border border-zinc-200 mx-4" />
            <div class="flex flex-col gap-y-2 px-4">
                <div
                    v-for="item in subMenu"
                    v-show="!item.disable"
                    class="w-full px-3"
                >
                    <button
                        class="textNeutral2 !w-fit flex gap-1"
                        @click="handleClick(item)"
                    >
                        <i
                            v-show="item.icon"
                            class="icon"
                            :class="item.icon"
                        />
                        <span
                            class="button-1"
                            :class="item.color"
                        >{{ item.copy }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <VDropdown
        positioning-disabled
        :shown="showLangDialog"
    >
        <template #popper="{ hide }">
            <div class="w-screen h-screen bg-neutral-800 bg-opacity-25 flex justify-center items-center">
                <div class="mx-2 w-full max-h-[400px] h-fit bg-white rounded-xl shadow border border-[#dee2e6] flex-col justify-start items-start inline-flex">
                    <div class="self-stretch pl-4 pr-2 py-2 border-b border-[#dee2e6] justify-start items-center gap-2 inline-flex">
                        <div class="grow shrink basis-0 h-6 justify-start items-start gap-2.5 flex">
                            <div class="grow shrink basis-0 text-[#212529] text-lg font-semibold font-['Source Sans 3'] leading-normal tracking-tight">
                                Select language
                            </div>
                        </div>
                        <div class="justify-end items-center gap-1 flex h-9 w-9">
                            <div class="h-9 w-9 rounded-lg justify-center items-center flex textNeutral textNeutral-grey">
                                <div class="h-6 w-6 icon icon-close-filled" />
                            </div>
                        </div>
                    </div>

                    <div class="p-3 flex flex-col gap-1 w-full">
                        <div
                            v-for="([key, value]) in Object.entries(languageDataCommon.languages)"
                            class="p-1 w-full rounded-[4px] hover:cursor-pointer"
                            :class="language === key ? 'bg-[#f4f6f8]' : ''"
                            @click="switchLang(key)"
                        >
                            <div class="flex justify-between w-full items-center">
                                <span class="body-2 px-2">{{ value }} </span>

                                <i
                                    class="icon"
                                    :class="language === key ? 'icon-checkmark-filled' : ''"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </VDropdown>
</template>

<script src="./mlc-navigation.js" />

<style lang="scss" src="./mlc-navigation.scss"></style>
