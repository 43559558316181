import AccordionItem from '../../molecules/mlc-accordion-item/mlc-accordion-item.vue'

export default {
    components: {
        AccordionItem,
    },
    data () {
        return {
            openItemId: null,
        }
    },
    methods: {
        handleToggle (itemId) {
            this.openItemId = this.openItemId === itemId ? null : itemId
            this.$emit('toggleAccordion')
        },
    },
    props: {
        items: {
            required: true,
            type: Object,
        },
    },
}
