export default {
    component: () => import('./pg-order.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Order - Jauntr',
    },
    name: 'order',
    path: '/order/:session_id',
}
