<template>
    <section
        v-bind="$attrs"
        class="atm-section"
    >
        <slot />
    </section>
</template>

<script src="./atm-section.js" />
