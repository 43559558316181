import {
    ref,
    watch,
} from 'vue'

export default {
    data () {
        return {
            currentImage: this.data.slides.select.image,
            currentIndex: 0,
        }
    },
    inheritAttrs: false,
    methods: {
        href (url) {
            window.location.href = url
        },
        nextSlide () {
            this.currentIndex++
            // if(this.currentIndex > Object.keys(this.data.slides).length - 1) {
            //     this.$router.push(this.data.links.finish);
            // }
            this.currentImage = this.data.slides[Object.keys(this.data.slides)[this.currentIndex]].image
        },
        previousSlide () {
            this.currentIndex--
            // if(this.currentIndex < 0) {
            //   this.$router.push(this.data.links.cancel);
            // }
            this.currentImage = this.data.slides[Object.keys(this.data.slides)[this.currentIndex]].image
        },
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
    setup (props) {
        const localData = ref(props.data)
        watch(() => props.data, (newData) => {
            localData.value = newData
        })
        return {
            localData,
        }
    },
}
