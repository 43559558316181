// -- External libraries
import {
    createRouter,
    createWebHistory,
} from 'vue-router'

// -- Internal libraries
import scrollTo from '../utilities/dom/scroll-to.js'

// -- Routes
import * as routeFiles from '../pages/pg-routing.js'
const routes = Object.values(routeFiles)

const router = createRouter({
    history: createWebHistory(),
    routes: [...routes],
    scrollBehavior (to, from, savedPosition) {
        return savedPosition ? savedPosition : {
            top: 0,
        }
    },
})

// router.beforeEach(async (to, from, next) => {
//     next()
// })

router.afterEach((to) => {
    document.title = to.meta.title || 'Default Title'
})

// router.afterEach((to, from) => {
//     const elementId = to.hash?.replace('#', '') || to.meta?.ui?.autoscrollElementId || 'app'
//     const smoothAutoscroll = to.meta?.ui?.smoothAutoscroll

//     Vue.nextTick().then(() => {
//       if (!smoothAutoscroll) {
//         scrollTo({
//           id: elementId,
//           smooth: false,
//         })
//       } else if (elementId) {
//         scrollTo({
//           id: elementId,
//           smooth: true,
//         })
//       }
//     })
//   })
// router.afterEach(async (to) => {
//     const elementId = to.hash?.replace('#', '') || to.meta?.ui?.autoscrollElementId || 'app'
//     const smoothAutoscroll = to.meta?.ui?.smoothAutoscroll
//     if (!smoothAutoscroll) {
//         scrollTo({
//             id: elementId,
//             smooth: false,
//         })
//     } else if (elementId) {
//         setTimeout(() => {
//             scrollTo({
//                 id: elementId,
//                 smooth: true,
//             })
//         }, 300)
//     }
// })

export default router
