export default {
    component: () => import('./pg-processing.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Processing - Jauntr',
    },
    name: 'processing',
    path: '/processing',
}
