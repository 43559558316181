import {
    ref,
    watch,
} from 'vue'
import stringToAnchor from '../../../utilities/transformers/string-to-anchor.js'

export default {
    inheritAttrs: false,
    methods: {
        getAnchorTag (string) {
            return stringToAnchor(string)
        },
    },
    props: {
        items: {
            required: true,
            type: Object,
        },
    },
    setup (props) {
        const localItems = ref(props.items)
        watch(() => props.items, (newItems) => {
            localItems.value = newItems
        })
        return {
            localItems,
        }
    },
}
