<template>
    <div
        v-bind="$attrs"
        class="mlc-accordion-item"
    >
        <button
            class="mlc-accordion-item__button"
            @click="$emit('toggle')"
        >
            <h5
                class="mlc-accordion-item__button-header"
                v-html="title"
            />
            <atm-img
                :class="
                    isOpen
                        ? 'mlc-accordion-item__button-icon--open'
                        : 'mlc-accordion-item__button-icon'
                "
                src="../../../assets/icons/caret.svg"
            />
        </button>

        <div v-if="isOpen">
            <div class="mlc-accordion-item-content">
                <div v-if="!Array.isArray(content)">
                    <p
                        class="mlc-accordion-item-content-paragraph"
                        v-html="content"
                    />
                </div>
                <div
                    v-if="Array.isArray(content)"
                    class="mlc-accordion-item-content-multi"
                >
                    <div
                        v-for="(item, itemIndex) in content"
                        :key="itemIndex"
                    >
                        <div v-if="item.type === 'list'">
                            <ol class="mlc-accordion-item-content-list">
                                <li
                                    v-for="(listItem, listIndex) in item.content"
                                    :key="listIndex"
                                    class="mlc-accordion-item-content-list-item"
                                    v-html="listItem"
                                />
                            </ol>
                        </div>
                        <div v-if="item.type === 'text'">
                            <p
                                v-for="(p, pIndex) in item.content"
                                :key="pIndex"
                                class="mlc-accordion-item-content-paragraph"
                                v-html="p"
                            />
                        </div>

                        <div v-if="item.type === 'custom'">
                            <p class="mlc-accordion-item-content-paragraph">
                                {{ item.content[0] }}
                            </p>
                            <ul>
                                <li>
                                    <a
                                        class="mlc-accordion-item-content-paragraph"
                                        target="_blank"
                                        :href="environment.ext.chrome.url"
                                    >
                                        Google Chrome
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="mlc-accordion-item-content-paragraph"
                                        target="_blank"
                                        :href="environment.ext.edge.url"
                                    >
                                        Microsoft Edge
                                    </a>
                                </li>

                                <li>
                                    <p class="mlc-accordion-item-content-paragraph text-grey-5">
                                        {{ item.content[1] }}
                                    </p>
                                </li>
                            </ul>
                            <p class="mlc-accordion-item-content-paragraph">
                                {{ item.content[2] }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./mlc-accordion-item.js"></script>

<style lang="scss" src="./mlc-accordion-item.scss"></style>
