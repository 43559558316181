<template>
    <div
        v-bind="$attrs"
        class="mlc-accordian"
    >
        <atm-item
            v-for="(item, index) in items"
            :key="index"
            class="mlc-accordian__item"
        >
            <h4 class="mlc-accordian__item-header">
                {{ item.title }}
            </h4>

            <template v-if="!Array.isArray(item.content)">
                <p
                    :id="getAnchorTag(item.title)"
                    class="mlc-accordian__item-paragraph"
                >
                    {{ item.content }}
                </p>
            </template>
            <template v-if="Array.isArray(item.content)">
                <li
                    v-for="(content, contentIndex) in item.content"
                    :key="contentIndex"
                    v-html="content"
                />
            </template>
        </atm-item>
    </div>
</template>

<script src="./mlc-accordian.js"></script>

<style lang="scss" src="./mlc-accordian.scss"></style>
