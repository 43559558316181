<template>
    <div
        v-bind="$attrs"
        class="mlc-footer"
    >
        <mlc-grid class="mlc-footer__grid">
            <atm-item class="mlc-footer__grid-left">
                <slot name="left" />
            </atm-item>
            <atm-item class="mlc-footer__grid-middle">
                <slot name="middle" />
            </atm-item>
            <atm-item class="mlc-footer__grid-right">
                <slot name="right" />
            </atm-item>
        </mlc-grid>
    </div>
</template>

<script src="./mlc-footer.js" />

<style lang="scss" src="./mlc-footer.scss"></style>
