<template>
    <div
        v-bind="$attrs"
        class="atm-item"
    >
        <slot />
    </div>
</template>

<script src="./atm-item.js" />
