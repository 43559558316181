export default {
    component: () => import('./pg-how.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'How It Works - Jauntr',
    },
    name: 'how-it-works',
    path: '/how-it-works',
}
