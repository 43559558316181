export default {
    component: () => import('./pg-add.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Add To Browser - Jauntr',
    },
    name: 'browser-extension',
    path: '/browser-extension',
}
