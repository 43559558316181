import BaseAPI from './base-api-store';

export default class UserSessionAPI extends BaseAPI {
    // Method to handle redirect to login
    static async login (redirectUrl = '') {
        try {
            const url = redirectUrl
                ? `/user-session/sso-login?redirect_url=${encodeURIComponent(redirectUrl)}`
                : '/user-session/sso-login';

            // Make a request to the login redirect endpoint
            const response = await BaseAPI.fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Failed to redirect to login.');
            }

            // Assuming the API response contains the data required to perform the redirect
            const responseData = await response.json();
            return responseData.data; // Adjust the response data format as needed
        } catch (error) {
            console.error('Error redirecting user to login -', error);
            throw error;
        }
    }

    static async logout () {
        try {
            const response = await BaseAPI.fetch('/user/logout', {
                credentials: 'include',
                headers: {
                    'Access-Control-Allow-Credentials': true,
                },
                method: 'POST',
            });

            if (!response.ok) {
                throw new Error('Failed to log out.');
            }

            console.log(response)

            // Assuming the API response contains a message or data indicating success
            // const responseData = await response.json();
            // return responseData.message || 'Logged out successfully'; // Adjust the response data format as needed

        } catch (error) {
            console.error('Error logging out user -', error);
            throw error;
        }
    }

}
