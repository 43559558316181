export default {
    component: () => import('./pg-search.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Search - Jauntr',
    },
    name: 'search',
    path: '/search',
}
