<template>
    <div class="mlc-document">
        <atm-section
            v-for="(content, contentIndex) in content"
            :key="contentIndex"
            class="mlc-document__section"
        >
            <h2>{{ content.heading }}</h2>
            <template
                v-for="(body, bodyIndex) in content.body"
                :key="bodyIndex"
            >
                <h3
                    v-if="body.subheading"
                    v-html="body.subheading"
                />
                <p
                    v-if="body.paragraph"
                    v-html="body.paragraph"
                />
                <ul v-if="body.list">
                    <li
                        v-for="(listItem, listIndex) in body.list"
                        :key="listIndex"
                        v-html="listItem"
                    />
                </ul>
            </template>
        </atm-section>
    </div>
</template>

<script src="./mlc-document.js" />

<style lang="scss" src="./mlc-document.scss"></style>
