// tracking.js
import { useGtag } from 'vue-gtag-next'
import { useRouter } from 'vue-router'
import environment from '../../.configs/app/environment'
import { useHotjar } from './use-hotjar'

export function useAnalytics () {
    const {
        event,
    } = useGtag()
    const router = useRouter()
    const {
        getHotjarUserId,
    } = useHotjar()

    function trackEvent (eventName, eventParameters = {}, pageContext = {}) {
        try {
            let currentPath = router.currentRoute.value.path.slice(1).replaceAll('/', '_')
            if (currentPath === '') {
                currentPath = 'home'
            }
            if (currentPath === 'search' && pageContext.step) {
                if (pageContext.step <= 1) {
                    currentPath = 'search_departure'
                }
                if (pageContext.step === 2) {
                    currentPath = 'search_arrival'
                }
            }

            // Get Hotjar user ID
            const hotjarUserId = getHotjarUserId()

            event(`${currentPath}_${eventName}`, {
                ...eventParameters,
                hotjar_session_id: hotjarUserId,
                page_path: currentPath,
                send_to: environment.app.gaId,
                version: '1.0.0',
            })
        } catch (error) {
            console.error('Error tracking event', error)
        }
    }

    return {
        trackEvent,
    }
}

export function useAds () {
    const {
        event,
    } = useGtag()
    async function trackConversions (value, orderId) {
        try {
            event('conversion', {
                currency: 'CAD',
                // When using useGtag's events, it does not track conversions correctly
                send_to: `${environment.app.gaConversionId}/${environment.app.gaConversionLabel}`,
                transaction_id: await hash(orderId),
                value: value, // Price of trip
                version: '1.0.0',
                // TODO: temp hash, need to actually anonymize according to GDPR
            })
        } catch (error) {
            console.error('Error tracking conversion', error)
        }
    }

    function trackPurchaseAttempt () {
        try {
            // Twitter
            if (typeof window.twq === 'function' && environment.app.xPixelCodePurchaseAttemptId) {
                window.twq('event', environment.app.xPixelCodePurchaseAttemptId, {});
            }

            // Reddit
            if (typeof window.rdt === 'function' && environment.app.redditPixelCodePurchaseAttemptId) {
                window.rdt('track', 'Custom', {
                    customEventName: environment.app.redditPixelCodePurchaseAttemptId,
                });
            }

        } catch (error) {
            console.error('Error tracking purchase attempt:', error);
        }
    }

    function trackPurchaseComplete () {
        try {
            // Twitter
            if (typeof window.twq === 'function' && environment.app.xPixelCodePurchaseCompleteId) {
                window.twq('event', environment.app.xPixelCodePurchaseCompleteId, {});
            }

            // Reddit
            if (typeof window.rdt === 'function' && environment.app.redditPixelCodePurchaseCompleteId) {
                window.rdt('track', 'Custom', {
                    customEventName: environment.app.redditPixelCodePurchaseCompleteId,
                });
            }

        } catch (error) {
            console.error('Error tracking purchase complete:', error);
        }
    }

    return {
        trackConversions,
        trackPurchaseAttempt,
        trackPurchaseComplete,
    }
}

async function hash (string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(string)

    const hashBuffer = await crypto.subtle.digest('SHA-256', data)

    const hashArray = [...new Uint8Array(hashBuffer)]
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}
