export default {
    component: () => import('./pg-vertoflex.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Vertoflex - Jauntr',
    },
    name: 'vertoflex',
    path: '/vertoflex',
}
