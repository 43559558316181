<template>
    <button
        v-bind="$attrs"
        class="atm-button"
    >
        <slot />
    </button>
</template>

<script src="./atm-button.js" />
<style lang="scss" src="./atm-button.scss"></style>
