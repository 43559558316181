export default {
    component: () => import('./pg-review.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Review - Jauntr',
    },
    name: 'checkout',
    path: '/checkout',
}
