<template>
    <div
        v-bind="$attrs"
        class="mlc-list"
    >
        <slot />
    </div>
</template>

<script src="./mlc-list.js" />
