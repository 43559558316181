<template>
    <div
        v-if="visible"
        class="modal-overlay"
    >
        <div class="modal-container">
            <div class="modal-body">
                <h3 class="modal-header">
                    {{ headerText }}
                </h3>
                <div
                    class="modal-description"
                    v-html="bodyText"
                />
            </div>

            <div class="modal-footer">
                <button @click="closeModal">
                    Close
                </button>
            </div>
        </div>
    </div>
</template>

<script src="./org-modal.js" />
<style lang="scss" src="./org-modal.scss"></style>
