import {
    computed,
    getCurrentInstance,
    inject,
    onMounted,
    provide,
    ref,
    watch,
} from 'vue'
import {
    useRoute,
    useRouter,
} from 'vue-router'
import environment from '../../../../.configs/app/environment.js'
import useBrowser from '../../../utilities/use-browser.js'
import Footer from '../../molecules/pg-footer/pg-footer.vue'
import 'floating-vue/dist/style.css'
import { useIdle } from '@vueuse/core'
import {
    Dropdown as VDropdown,
    options,
} from 'floating-vue'
import PointsService from '../../../services/points-service.js'
import UserSessionService from '../../../services/user-session-service.js'
import UserService from '../../../services/users-service.js'
import { useUserSessionStore } from '../../../stores/user-session-store.js'
import { useUserStore } from '../../../stores/user-store.js'
import { useAnalytics } from '../../../utilities/use-analytics.js'

export default {
    components: {
        Footer,
    },

    setup () {
        const instance = getCurrentInstance()
        const app = instance.appContext.app
        app.component('v-dropdown', VDropdown)

        const {
            trackEvent,
        } = useAnalytics()
        const route = useRoute()
        const showDialog = ref()

        const localUser = ref({})

        const showLoginModal = ref()

        const updateShowDialog = (value) => {
            showDialog.value = value
        }

        provide('showDialog', {
            showDialog,
            updateShowDialog,
        })

        const extensionLabel = inject('$extensionLabel')

        const {
            isTouch,
        } = useBrowser()

        const href = (url, inNewTab, addToChromeButton) => {
            if (isTouch) {
                showDialog.value = true
            } else {
                if (inNewTab) {
                    window.open(url, '_blank')
                } else {
                    window.location.href = url
                }
            }

            if (inNewTab) {
                window.open(url, '_blank')
            } else {
                window.location.href = url
            }
        }

        const goToHash = (item) => {
            if (route.path === '/checkout') {
                trackEvent(item.trackEvent)
            }

            if (item.hash) {
                location.hash = item.hash
            }
        }

        const languageData = inject('$languageData').value.common

        const navigation = ref({
            action: {
                copy: extensionLabel,
                href: environment.ext.url,
            },
            logo: {
                alt: languageData.jauntrLogoAlt,
                name: 'home',
                src: '/assets/images/jauntr-logo.svg',
            },
            menu: {
                addToBrowser: {
                    copy: languageData.browserExtensionNavButton,
                    name: 'browser-extension',
                    trackEvent: 'ClickHeader_BrowserExtensionPage',
                },
                howItWorks: {
                    copy: languageData.howItWorksNavButton,
                    name: 'how-it-works',
                    trackEvent: 'ClickHeader_HowItWorksPage',
                },
                // about: {
                //     copy: languageData.aboutUsNavButton,
                //     name: 'about-us'
                // },
                points: {
                    copy: languageData.pointsNavButton,
                    name: 'points',
                    trackEvent: 'ClickHeader_PointsPage',
                },
                support: {
                    copy: languageData.faq,
                    name: 'faq-general',
                    trackEvent: 'ClickHeader_FAQPage',
                },
            },
        })

        const footerSections = [
            {
                links: [
                    {
                        name: 'home',
                        text: languageData.homeFooterLink,
                    },
                    {
                        name: 'how-it-works',
                        text: languageData.howItWorksNavButton,
                    },
                    {
                        name: 'points',
                        text: languageData.pointsNavButton,
                    },
                    // { text: languageData.aboutUsNavButton, name: "about-us" },
                    // { text: languageData.supportNavButton, name: "support" },
                ],
                separator: true,
                title: 'Jauntr',
            },
            {
                links: [
                    {
                        name: 'faq-general',
                        text: languageData.faqGeneralFooterLink,
                    },
                    {
                        name: 'faq-bookings',
                        text: languageData.faqBookingsFooterLink,
                    },
                    {
                        name: 'faq-pricing',
                        text: languageData.faqFooterHeader,
                    },
                    //     { text: languageData.faqOffersFooterLink, name: "faq-offers" },
                    //     { text: languageData.faqPointsFooterLink, name: "faq-points" },
                ],
                separator: true,
                title: languageData.faq,
            },
            {
                links: [
                    {
                        name: 'terms-and-conditions',
                        text: languageData.termsFooterLink,
                    },
                    {
                        name: 'privacy-policy',
                        text: languageData.privacyPolicyFooterLink,
                    },
                    {
                        name: 'faq-general',
                        text: languageData.contactFooterLink,
                    },
                ],
                separator: true,
                title: `© ${new Date().getFullYear()}`,
            },
            // {
            //     title: "Social Links",
            //     separator: true,
            //     links: [
            //         { text: "Facebook", },
            //         { text: "Twitter", },
            //         { text: "Instagram", },
            //     ],
            // },
        ]

        const toggleLoginModal = (hide) => {
            if (userSessionStore.auth) {}
            else {
                showLoginModal.value = true

                if (!hide) {
                    trackEvent('ClickCTA_Login')
                }

                if (hide) {
                    hide()
                    showLoginModal.value = false
                }
            }
        }

        const login = async () => {
            let data
            try {
                data = await UserSessionService.login()
            } catch {
                return
            }

            window.location.href = data.redirect_url
        }

        const logout = async () => {
            await UserSessionService.logout()
            userSessionStore.clear()
        }

        const showUserManageModal = ref()

        const userStore = useUserStore()

        const toggleUserManageModal = async (hide) => {
            if (hide) {
                hide()
                showUserManageModal.value = false
                userManageStep.value = 0
            } else {
                showUserManageModal.value = true

                const userData = await UserService.show()

                if (userData) {
                    userStore.setUserData(userData)
                    localUser.value = userStore.getUserDate()
                }
            }
        }

        const handleClick = (item, hide) => {
            if (item.value === 'account') {
                hide()
                toggleUserManageModal()
            }

            if (item.value === 'logout') {
                logout()
            }
        }

        const userManageStep = ref(0)
        //  0 - start
        //  1 - edit name
        //  2 - delete account
        //  3 - account deleted

        const resetKey = ref(0)
        const updateEditNameStep = (value) => {
            if (userManageStep.value === 1) {
                localUser.value.firstName = ''
                localUser.value.lastName = ''
                resetKey.value += 1
            }

            userManageStep.value = value
        }

        const getModalTitle = computed(() => {
            if (userManageStep.value == 0 || userManageStep.value == 1) {
                return languageData.manageAccount
            }
            if (userManageStep.value == 2) {
                return languageData.deleteJauntrAccount
            }
            if (userManageStep.value == 3) {
                return languageData.accountDeleted
            }
        })

        const deletingAccount = ref()

        const deleteAccount = () => {
            deletingAccount.value = true
        }

        const getCookie = (name) => {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) return parts.pop().split(';').shift()
            return null
        }

        const userSessionStore = useUserSessionStore()

        const updateUserName = async () => {
            await UserService.update({
                first_name: localUser.value.firstName,
                last_name: localUser.value.lastName,
            })
        }

        const balance = ref({})
        const displayBalance = async () => {
            try {
                const response = await PointsService.display()
                balance.value = response.points
                console.log(balance.value)
            } catch {
                return
            }
        }
        const rewards = computed(() => {
            const calculatedRewards = balance.value / 100
            return new Intl.NumberFormat('en-US', {
                currency: 'USD',
                style: 'currency',
            }).format(calculatedRewards)
        })

        const initials = computed(() => {
            return userStore.firstName && userStore.lastName
                ? `${userStore.firstName.charAt(0)}${userStore.lastName.charAt(0)}`
                : 'null'
        })

        onMounted(async () => {
            const res = await UserService.show()
            if (res && res.id) {
                userSessionStore.setAuthStatus(true)
                displayBalance()
            } else {
                userSessionStore.setAuthStatus(false)
            }
        })

        const {
            idle,
            reset,
        } = useIdle(20_000) //  20s

        // watch(idle, (idleValue) => {
        //     if (idleValue) {
        //         trackEvent('user_inactive')
        //         reset()
        //     }
        // })

        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                trackEvent('returned_tab')
            }
            if (document.visibilityState === 'hidden') {
                trackEvent('left_tab')
            }
        })

        const dropdownOpen = ref(false)
        const openDropdown = () => {
            dropdownOpen.value = true
            document.body.style.overflow = 'hidden'
        }
        const closeDropdown = () => {
            dropdownOpen.value = false
            if (showLoginModal.value) {
                showLoginModal.value = false
            }
            if (showUserManageModal.value) {
                showUserManageModal.value = false
                userManageStep.value = 0
            }
            document.body.style.overflow = ''
        }

        const router = useRouter()

        const navigateToPoints = (hide) => {
            router.push('/points')
            toggleUserManageModal(hide)
        }

        return {
            balance,
            closeDropdown,
            deleteAccount,
            deletingAccount,
            displayBalance,
            dropdownOpen,
            footerSections,
            getModalTitle,
            goToHash,
            handleClick,
            href,
            initials,
            languageData,
            localUser,
            login,
            logout,
            navigateToPoints,
            navigation,
            openDropdown,
            resetKey,
            rewards,
            route,
            router,
            showDialog,
            showLoginModal,
            showUserManageModal,
            toggleLoginModal,
            toggleUserManageModal,
            updateEditNameStep,
            updateUserName,
            userManageStep,
            userSessionStore,
            userStore,
        }
    },
}
