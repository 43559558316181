export function useHotjar () {
    /**
   * Get Hotjar user ID
   * @returns {string|null} Hotjar user ID or null if not available
   */
    const getHotjarUserId = () => {

        if (window.hj && window.hj.bridge && typeof window.hj.bridge.getSessionUserId === 'function') {
            const hotjarUserId = window.hj.bridge.getSessionUserId()
            console.log('hotjarUserId', hotjarUserId)
            return hotjarUserId
        }
        console.log('Hotjar not initialized or user ID not available')
        return null
    }

    return {
        getHotjarUserId,
    }
}

export default useHotjar