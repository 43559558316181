export default {
    component: () => import('./pg-privacy.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Privacy Policy - Jauntr',
    },
    name: 'privacy-policy',
    path: '/privacy-policy',
}
