export default {
    component: () => import('./pg-points.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Points - Jauntr',
    },
    name: 'points',
    path: '/points',
}
