export default {
    component: () => import('./pg-terms.vue'),
    meta: {
        accessControl: {
            public: true,
        },
        title: 'Terms and Conditions - Jauntr',
    },
    name: 'terms-and-conditions',
    path: '/terms-and-conditions',
}
